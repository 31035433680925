import { storeActions } from "./actions";
import { storeGetters } from "./getters";
import { storeMutations } from "./mutations";
import { PhotographyImage } from "@/models/photography/image";

export interface PhotographyImageStoreState {
  collection: PhotographyImage[];
  collectionLoading: boolean;
  resource: PhotographyImage;
  resourceLoading: boolean;
}

export const storeModule = {
  namespaced: true,
  state: {
    collection: [],
    collectionLoading: false,
    resource: undefined,
    resourceLoading: false,
  },
  mutations: storeMutations,
  actions: storeActions,
  getters: storeGetters,
};
