import Vue from "vue";
import Vuex from "vuex";

import { storeModule as PhotographyGalleryStoreState } from "@/store/photography/gallery";
import { storeModule as PhotographyImageStoreState } from "@/store/photography/image";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    "Photography.Gallery": PhotographyGalleryStoreState,
    "Photography.Image": PhotographyImageStoreState,
  },
});
