import { Pagination } from "@/models/Pagination";
import { HTTPService, HTTPServiceResolve } from "@/services/http-service";
import { ApiV3Routes, routeBuilder } from "@/types/Routes";

const httpService = new HTTPService();

export const storeActions = {
  fetch: (context: any, options?: any) => {
    return new Promise((resolve, reject) => {
      const url = routeBuilder(ApiV3Routes.PHOTOGRAPHY_GALLERIES, options);
      context.commit("set_collection_loading_state", true);

      httpService
        .get(url)
        .then((response: HTTPServiceResolve) => {
          if (!response.collection) {
            resolve({
              collection: [],
            });
          } else {
            context.commit("set_collection", response.collection);
          }

          resolve({
            collection: response.collection,
            pagination: Pagination.fromMeta(response.meta),
          });
        })
        .finally(() => context.commit("set_collection_loading_state", false));
    });
  },

  read: (context: any, options?: any) => {
    return new Promise((resolve, reject) => {
      const url = routeBuilder(ApiV3Routes.PHOTOGRAPHY_GALLERY, options);

      context.commit("set_resource_loading_state", true);

      httpService
        .get(url)
        .then((response: HTTPServiceResolve) => {
          if (!response.resource) {
            resolve(null);
          } else {
            context.commit("set_resource", response.resource);
          }

          resolve({ resource: response.resource });
        })
        .catch(() => reject())
        .finally(() => context.commit("set_resource_loading_state", false));
    });
  },
};
