import Vue from "vue";
import { PhotographyImage } from "@/models/photography/image";
import { PhotographyImageStoreState } from ".";
import { concat as _concat } from "lodash";

export const storeMutations = {
  set_collection_loading_state(
    state: PhotographyImageStoreState,
    payload: boolean
  ) {
    Vue.set(state, "collectionLoading", payload);
  },

  set_collection(
    state: PhotographyImageStoreState,
    payload: PhotographyImage[]
  ) {
    Vue.set(state, "collection", payload);
  },

  set_and_append_collection(
    state: PhotographyImageStoreState,
    payload: PhotographyImage[]
  ) {
    Vue.set(state, "collection", _concat(state.collection, payload));
  },

  set_resource_loading_state(
    state: PhotographyImageStoreState,
    payload: boolean
  ) {
    Vue.set(state, "resourceLoading", payload);
  },

  set_resource(state: PhotographyImageStoreState, payload: PhotographyImage) {
    Vue.set(state, "resource", payload);
  },
};
