import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";

import { default as PhotographyImageOverview } from "../views/photography/image/overview/index.vue";
import { default as PhotographyImageDetail } from "../views/photography/image/detail/index.vue";

import { default as PhotographyGalleryOverview } from "../views/photography/gallery/overview/index.vue";
import { default as PhotographyGalleryDetail } from "../views/photography/gallery/detail/index.vue";
import { default as PhotographyGallerySlideshow } from "../views/photography/gallery/slideshow/index.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:principal_slug/photography/images",
    name: "photography_images__index",
    component: PhotographyImageOverview,
  },
  {
    path: "/:principal_slug/photography/images/:id",
    name: "photography_images__show",
    component: PhotographyImageDetail,
  },
  {
    path: "/:principal_slug/photography/galleries",
    name: "photography_galleries__index",
    component: PhotographyGalleryOverview,
  },
  {
    path: "/:principal_slug/photography/galleries/:id",
    name: "photography_galleries__show",
    component: PhotographyGalleryDetail,
  },
  {
    path: "/:principal_slug/photography/galleries/:id/slideshow",
    name: "photography_galleries__slideshow",
    component: PhotographyGallerySlideshow,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
