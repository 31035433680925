import { PhotographyGalleryStoreState } from ".";
import { PhotographyGallery } from "@/models/photography/gallery";

export const storeGetters = {
  collection(state: PhotographyGalleryStoreState) {
    return (): PhotographyGallery[] =>
      PhotographyGallery.collectionFactory(state.collection);
  },

  collectionLoading(state: PhotographyGalleryStoreState) {
    return (): boolean => state.collectionLoading;
  },

  resource(state: PhotographyGalleryStoreState) {
    return (): PhotographyGallery | null =>
      state.resource
        ? PhotographyGallery.resourceFactory(state.resource)
        : null;
  },

  resourceLoading(state: PhotographyGalleryStoreState) {
    return (): boolean => state.resourceLoading;
  },
};
