class PaginationLinks {
  prev: string | undefined;
  next: string | undefined;
  first: string | undefined;
  last: string | undefined;

  constructor(args: any) {
    Object.assign(this, args);
  }
}

export class Pagination {
  public page = 1;
  public per = 12;
  public totalPages: number | undefined;
  public totalCount: number | undefined;

  public resourceClass: string | undefined;
  public maxUpdatedAt: Date | undefined;

  public links: PaginationLinks | undefined;

  constructor(args?: any) {
    Object.assign(this, args);
  }

  static fromMeta(payload: any): Pagination {
    const newObject: Pagination = new Pagination();
    newObject.page = payload.page;
    newObject.per = payload.per;
    newObject.resourceClass = payload.resource_class;
    newObject.maxUpdatedAt = payload.max_updated_at;
    newObject.totalPages = payload.total_pages;
    newObject.totalCount = payload.total_count;

    newObject.links = new PaginationLinks(payload.links);

    return newObject;
  }
}
