import { ResponsiveImage } from "@/models/responsive_image";
import { IPhotographyGalleryConstructor, PhotographyGallery } from "./gallery";

interface IPhotographyImageConstructor {
  id: string;
  key: string;
  gallery?: IPhotographyGalleryConstructor;
  responsive_image: ResponsiveImage;
  next_gallery_image?: IPhotographyImageConstructor;
  previous_gallery_image?: IPhotographyImageConstructor;
}

export class PhotographyImage {
  public id: string;
  public key: string;

  public responsive_image: ResponsiveImage;
  public gallery?: PhotographyGallery;

  public next_gallery_image?: PhotographyImage;
  public previous_gallery_image?: PhotographyImage;

  public constructor(payload: IPhotographyImageConstructor) {
    this.id = payload.id;
    this.key = payload.key;

    this.responsive_image = ResponsiveImage.resourceFactory(
      payload.responsive_image
    );

    if (payload.gallery) {
      this.gallery = PhotographyGallery.resourceFactory(payload.gallery);
    }

    if (payload.next_gallery_image) {
      this.next_gallery_image = PhotographyImage.resourceFactory(
        payload.next_gallery_image
      );
    }

    if (payload.previous_gallery_image) {
      this.previous_gallery_image = PhotographyImage.resourceFactory(
        payload.previous_gallery_image
      );
    }
  }

  public static collectionFactory(
    collection: IPhotographyImageConstructor[] | PhotographyImage[]
  ): PhotographyImage[] {
    return collection.map(PhotographyImage.resourceFactory);
  }

  public static resourceFactory(
    payloadOrResource: IPhotographyImageConstructor | PhotographyImage
  ): PhotographyImage {
    if (payloadOrResource instanceof PhotographyImage) {
      return payloadOrResource;
    }

    return new PhotographyImage(payloadOrResource);
  }

  public url(router: any, route: any): string {
    const path = router.resolve({
      name: "photography_images__show",
      params: {
        principal_slug: route.params.principal_slug,
        id: this.key,
      },
    }).href;

    return `${window.location.origin}${path}`;
  }
}
