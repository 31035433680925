import { ResponsiveImage } from "@/models/responsive_image";

export interface IPhotographyGalleryConstructor {
  id: string;
  label: string;
  url: string;
  point_of_time: Date;
  responsive_image?: ResponsiveImage;
}

export class PhotographyGallery {
  public id: string;
  public label: string;
  public path: string;
  public point_of_time: Date;

  public responsive_image?: ResponsiveImage;

  public constructor(payload: IPhotographyGalleryConstructor) {
    this.id = payload.id;
    this.label = payload.label;
    this.path = payload.url;
    this.point_of_time = payload.point_of_time;

    if (payload.responsive_image) {
      this.responsive_image = ResponsiveImage.resourceFactory(
        payload.responsive_image
      );
    }
  }

  public static collectionFactory(
    collection: IPhotographyGalleryConstructor[] | PhotographyGallery[]
  ): PhotographyGallery[] {
    return collection.map(PhotographyGallery.resourceFactory);
  }

  public static resourceFactory(
    payloadOrResource: IPhotographyGalleryConstructor | PhotographyGallery
  ): PhotographyGallery {
    if (payloadOrResource instanceof PhotographyGallery) {
      return payloadOrResource;
    }

    return new PhotographyGallery(payloadOrResource);
  }

  public url(router: any, route: any): string {
    const path = router.resolve({
      name: "photography_galleries__show",
      params: {
        principal_slug: route.params.principal_slug,
        id: this.path,
      },
    }).href;

    return `${window.location.origin}${path}`;
  }
}
