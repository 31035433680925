import { PhotographyImageStoreState } from ".";
import { PhotographyImage } from "@/models/photography/image";

import { uniqBy as _uniqBy } from "lodash";
export const storeGetters = {
  collection(state: PhotographyImageStoreState) {
    return (): PhotographyImage[] =>
      _uniqBy(
        PhotographyImage.collectionFactory(state.collection),
        (e) => e.id
      );
  },

  collectionLoading(state: PhotographyImageStoreState) {
    return (): boolean => state.collectionLoading;
  },

  resource(state: PhotographyImageStoreState) {
    return (): PhotographyImage | null =>
      state.resource ? PhotographyImage.resourceFactory(state.resource) : null;
  },

  resourceLoading(state: PhotographyImageStoreState) {
    return (): boolean => state.resourceLoading;
  },
};
