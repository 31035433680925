import moment from "moment-timezone";
import Vue from "vue";
import VueMeta from "vue-meta";
import VueMoment from "vue-moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.min.css";

import { default as ResponsiveImage } from "@/components/ResponsiveImage.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faDownload,
  faSpinner,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
library.add(faDownload);
library.add(faSpinner);
library.add(faCaretLeft);
library.add(faCaretRight);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

import Notifications from "vue-notification";
Vue.use(Notifications);

Vue.component("ResponsiveImage", ResponsiveImage);

moment.locale("de");
Vue.use(VueMoment, {
  moment,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
