import _, { first as _first, orderBy as _orderBy } from "lodash";

interface IResponsiveImageFallback {
  x1: string;
  x2?: string;
  x3?: string;
}

interface IResponsiveImageRedention {
  media: number;
  x1: string;
  x2?: string;
  x3?: string;
}

interface IResponsiveImageConstructor {
  id?: string;
  alt?: string;
  title?: string;
  fallback: IResponsiveImageFallback;
  redentions: IResponsiveImageRedention[];
}

class ResponsiveImageFallback {
  public x1: string;
  public x2?: string;
  public x3?: string;

  public constructor(payload: IResponsiveImageFallback) {
    this.x1 = payload.x1;
    this.x2 = payload.x2;
    this.x3 = payload.x3;
  }
}

export class ResponsiveImageRedention {
  public media: number;
  public x1: string;
  public x2?: string;
  public x3?: string;

  public constructor(payload: IResponsiveImageRedention) {
    this.media = payload.media;
    this.x1 = payload.x1;
    this.x2 = payload.x2;
    this.x3 = payload.x3;
  }
}

export class ResponsiveImage {
  public id?: string;
  public alt?: string;
  public title?: string;

  public fallback: ResponsiveImageFallback;
  public redentions: ResponsiveImageRedention[];

  public constructor(payload: IResponsiveImageConstructor) {
    this.id = payload.id;
    this.alt = payload.alt;
    this.title = payload.title;

    this.fallback = new ResponsiveImageFallback(payload.fallback);
    this.redentions = _orderBy(payload.redentions, ["media"], ["desc"]).map(
      (e) => new ResponsiveImageRedention(e)
    );
  }

  public static collectionFactory(
    collection: IResponsiveImageConstructor[] | ResponsiveImage[]
  ): ResponsiveImage[] {
    return collection.map(ResponsiveImage.resourceFactory);
  }

  public static resourceFactory(
    payloadOrResource: IResponsiveImageConstructor | ResponsiveImage
  ): ResponsiveImage {
    if (payloadOrResource instanceof ResponsiveImage) {
      return payloadOrResource;
    }

    return new ResponsiveImage(payloadOrResource);
  }

  public get highestRendention(): ResponsiveImageRedention | undefined {
    return _first(this.redentions);
  }

  public get imageAvailable(): boolean {
    return this.redentions.map((e) => e.x1).filter((n) => n).length > 0;
  }
}
