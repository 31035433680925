import axios from "axios";

import {
  each as _each,
  isArray as _isArray,
  isEqual as _isEqual,
} from "lodash";

interface HTTPServiceMeta {
  page?: number;
  per?: number;
  total_pages: number;
  total_count: number;
}

export interface HTTPServiceResolve {
  resource?: Record<string, any>;
  collection?: Array<any>;
  links?: HTTPServiceLinks;
  meta: HTTPServiceMeta;
  data?: any;
}

interface HTTPServiceLink {
  url: string;
}

interface HTTPServiceLinks {
  first?: HTTPServiceLink;
  prev?: HTTPServiceLink;
  next?: HTTPServiceLink;
  last?: HTTPServiceLink;
}

export class HTTPService {
  static instance: any;

  constructor() {
    if (HTTPService.instance) {
      return HTTPService.instance;
    }

    HTTPService.instance = this;
  }

  private get axiosInstance() {
    return axios.create({
      // baseURL: 'https://squibble.dev.squibble.me',
      // baseURL: 'https://squibble.me',
      // baseURL: 'https://obseed.me',
      // timeout: 1000,
      // headers: {'X-Custom-Header': 'foobar'}
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  private parseLinkHeader(header: any): HTTPServiceLinks | undefined {
    if (!header) {
      return;
    }

    if (header.length == 0) {
      throw new Error("input must not be of zero length");
    }

    const links: HTTPServiceLinks = {};

    // Parse each part into a named link
    _each(header.split(","), (p) => {
      const section = p.split(";");

      if (section.length != 2) {
        throw new Error("section could not be split on ';'");
      }

      const url: string = section[0].replace(/<(.*)>/, "$1").trim();
      const name: string = section[1].replace(/rel="(.*)"/, "$1").trim();

      // eslint-disable-next-line
      // @ts-ignore
      links[name] = { url: url };
    });

    return links;
  }

  public get(url: string, rawResponse = false): Promise<any> {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url)
        .then((response) => {
          if (rawResponse) {
            resolve(response);
          } else {
            if (_isEqual(response.status, 204)) {
              resolve({
                resource: null,
                collection: null,
                links: null,
                meta: {
                  page: null,
                  per: null,
                  total_count: 0,
                  total_pages: 0,
                } as unknown as HTTPServiceMeta,
              } as unknown as HTTPServiceResolve);
            } else {
              resolve({
                resource: _isArray(response.data.data)
                  ? null
                  : response.data.data,
                collection: _isArray(response.data.data)
                  ? response.data.data
                  : null,
                links: this.parseLinkHeader(response.headers.link),
                meta: response.data.meta,
              } as HTTPServiceResolve);
            }
          }
        })
        .catch((error) => {
          // SentryService.sentryMessage(
          //   `Unable to execute HTTPService.get for ${url}`,
          //   {
          //     url: url,
          //     http_method: 'GET',
          //     status_code: error.response?.status,
          //     request_headers: error.config?.headers
          //   }
          // )

          reject(error);
        });
    });
  }

  public post(url: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, payload)
        .then((response) => {
          resolve({
            data: response.data.data,
            meta: response.data.meta,
          });
        })
        .catch((error) => {
          // SentryService.sentryMessage(
          //   `Unable to execute HTTPService.post for ${url}`,
          //   {
          //     url: url,
          //     http_method: 'POST',
          //     payload: payload,
          //     status_code: error.response?.status,
          //     request_headers: error.config.headers
          //   }
          // )

          reject();
        });
    });
  }

  public put(url: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(url, payload)
        .then((response) => {
          resolve({
            data: response.data.data,
            meta: response.data.meta,
          });
        })
        .catch((error) => {
          // SentryService.sentryMessage(
          //   `Unable to execute HTTPService.put for ${url}`,
          //   {
          //     url: url,
          //     http_method: 'PUT',
          //     payload: payload,
          //     status_code: error.response?.status,
          //     request_headers: error.config.headers
          //   }
          // )

          reject({
            data: error.response.data.data,
            meta: error.response.data.meta,
            error: {
              status_code: error.response?.status,
            },
          });
        });
    });
  }
}
