import {
  includes as _includes,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
} from "lodash";
// import moment from 'moment';
import querystring from "querystring";
import { Pagination } from "../models/Pagination";

export enum ApiV3Routes {
  PHOTOGRAPHY_GALLERIES = "/api/v3/:principal_slug/photography/galleries",
  PHOTOGRAPHY_GALLERY = "/api/v3/:principal_slug/photography/galleries/:id",
  PHOTOGRAPHY_IMAGES = "/api/v3/:principal_slug/photography/images",
  PHOTOGRAPHY_IMAGE = "/api/v3/:principal_slug/photography/images/:id",
}

enum ROUTE_BUILDER_OPTIONS {
  ORDER_BY = "order_by",
}

export const routeBuilder = (route: string, options: any = {}): string => {
  if (!route) {
    throw "[routeBuilder] Unable to process :route because there is none.";
  }

  for (const key in options) {
    if (!Object.prototype.hasOwnProperty.call(options, key)) {
      continue;
    } else if (_isEqual(key, ROUTE_BUILDER_OPTIONS.ORDER_BY)) {
      options[key] = options[key].map((e: Array<any>) => e.join(":")).join(",");
    } else if (_includes(route, `:${key}`)) {
      route = route.replace(`:${key}`, options[key]);
      delete options[key];
    }
  }

  const currentPagination: Pagination = options["pagination"];
  delete options["pagination"];

  if (currentPagination) {
    options.page = currentPagination.page;
    options.per = currentPagination.per;
  }

  // Sind hier noch :options vorhanden, so werden diese als Query Parameter
  // angehängt.
  //
  if (!_isEmpty(options)) {
    if (_includes(route, "?")) {
      route += `&${querystring.stringify(options)}`;
    } else {
      route += `?${querystring.stringify(options)}`;
    }
  }

  // return `https://rails-development.dev.squibble.me${route}`;
  // return `https://squibble.me${route}`;
  return route;
};
