import Vue from "vue";
import { PhotographyGallery } from "@/models/photography/gallery";
import { PhotographyGalleryStoreState } from ".";

export const storeMutations = {
  set_collection_loading_state(
    state: PhotographyGalleryStoreState,
    payload: boolean
  ) {
    Vue.set(state, "collectionLoading", payload);
  },

  set_collection(
    state: PhotographyGalleryStoreState,
    payload: PhotographyGallery[]
  ) {
    Vue.set(state, "collection", payload);
  },

  set_resource_loading_state(
    state: PhotographyGalleryStoreState,
    payload: boolean
  ) {
    Vue.set(state, "resourceLoading", payload);
  },

  set_resource(
    state: PhotographyGalleryStoreState,
    payload: PhotographyGallery
  ) {
    Vue.set(state, "resource", payload);
  },
};
